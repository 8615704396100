@font-face {
  font-family: Itim-Regular;
  src: url(fonts/Itim-Regular.ttf);
}

@font-face {
  font-family: RSU_Regular;
  src: url(fonts/RSU_Regular.ttf);
}

@font-face {
  font-family: Sarabun_Regular;
  src: url(fonts/Sarabun-Regular.ttf);
}

body {
  font-family: Itim-Regular !important;
}

::-webkit-scrollbar {
  width: 1px;
}

.lst-person {
  border: 1px solid #095c73;
  width: 300px;
  padding: 10px;
  margin: 6px;
  border-radius: 10px;
  cursor: pointer;
}

.lst-person:hover {
  background-color: #05404f;
}

.lst-person .header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 600;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.lst-person .body {
  padding-top: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  font-size: 12px;
}

.ck-content {
  background-color: black !important;
  color: white;
}

.drop-zone {
  width: 100%;
  border: 3px dashed #0c2d36;
  padding: 20px;
  border-radius: 15px;
  cursor: pointer;
  background-color: #2d5661;
  color: white;
}

.mouse-click:hover {
  cursor: pointer;
}

.img-box {
  width: 250px;
  height: 350px;
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  margin: 7px;
  position: relative;
}

.img-box .title {
  text-align: center;
  font-size: 30px;
  padding: 0px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  color: #ffffff;
  text-shadow: 2px 2px 0 #4074b5, 2px -2px 0 #4074b5, -2px 2px 0 #4074b5, -2px -2px 0 #4074b5, 2px 0px 0 #4074b5, 0px 2px 0 #4074b5, -2px 0px 0 #4074b5, 0px -2px 0 #4074b5;
}

.img-box .tag {
  position: absolute;
  top: 4px;
  left: 0px;
  width: 100%;
}

.home-img-title {
  background: rgba(0,0,0,0.5);
  margin-bottom: 0px;
  padding-bottom: 5px;
  padding-top: 10px;
  margin-top: 10px;
  word-wrap: break-word;
  white-space: initial;
  text-align: center;
}

.home-img-subtitle {
  background: rgba(0,0,0,0.5);
  margin: 0px;
  padding-bottom: 10px;
  word-wrap: break-word;
  white-space: initial;
  text-align: center;
}
